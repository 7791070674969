/* todo notes: first image flows over menu, fixed with z-index. Should investigate further */
.containers{
    background-color: white;
    padding-top: 50px;
}

.containers .title{
    text-align: center;
    font-size: 40px;
    line-height: 40px;
    height: 100px;
}

.containers .box img{
    width: 100%;
    max-width: 230px;
    max-height: 481px;
}

.containers p{
    margin: 0px;
    line-height: 27px;
}

.keep-track-images{
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding-right: 7%;
}

.keep-track-carousel{
    display: none;
    justify-content: center;
    align-items: center;
}

.kt-container, 
.advc-carousel,
.h-carousel{
    max-width: 230px;
}

.keep-track-images img{
    margin-left: 7%;
    width: 50%;
}

.keep-track, .add-view-customers{
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    background-color: #F1F1F1;
}

.keep-track h3, .keep-track p {
    padding-left: 15%;
    padding-right: 10%;
    text-align: justify;
}

.box.keep-track-mobile, 
.box.advc-imgs .advc-carousel,
.box.add-view-customers-mobile,
.box.advc-mobile,
.box.container-history .h-carousel{
    display: none;
}

.manage-trips{
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.manage-trips-carousel{
    display: none;
    max-width: 230px;
}

.manage-trips-images{
    display: flex;
    justify-content: center;
    align-items: center;
}

.manage-trips-images img{
    margin-top: 10%;
    margin-right: 10%;
    margin-left:  10%;
    max-height: 481px;
}

.add-view-customers div{
    max-width: 524px;
    align-self: center;
    align-items: center;
    text-align: center;
}

.advc-imgs{
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-left: 7%;
}

.advc-imgs img{
    margin-right: 7%;
    width: 50%;
}

.container-history{
    padding-bottom: 60px;
    padding-top: 60px;
    background-color: #F1F1F1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.container-history h3{
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 5px;
}

.container-history .h-imgs{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.container-history .h-imgs img:first-child{
    margin-right: 50px;
}

@media screen and (max-width: 1220px) {

    .keep-track-images{
        display: none;
    }

    .keep-track-carousel{
        display: flex;
    }

    .manage-trips-images img{
        margin-right: 4%;
        margin-left:  4%;
    }

    .box.advc-imgs .advc-img{
        display: none;
    }

    .box.advc-imgs .advc-carousel{
        display: flex;
    }

    .box.advc-imgs{
        justify-content: center;
        align-items: center;
    }

    .box.add-view-customers p{
        text-align: justify;
        padding: 0 8%;
    }    
}

@media screen and (max-width: 1095px) {
    .advc-imgs{
        padding-left: 2%;
    }
}

@media screen and (max-width: 845px){
    .manage-trips-images img{
        margin-right: 1%;
        margin-left:  1%;
    }
}

@media screen and (max-width: 730px){

    .containers .box, 
    .manage-trips-images{
        display: none;
    }

    .box.manage-trips, 
    .manage-trips-carousel,
    .box.container-history,
    .box.container-history .h-carousel {
        display: flex;
    }

    .box.manage-trips{
        background-color: #F1F1F1;
    }

    .box.keep-track-mobile{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }

    .containers .box h3{
        margin-top: 0;
    }

    .containers .box{
        padding-top: 10%;
        padding-left: 8%;
        padding-right: 8%;
        padding-bottom: 10%;
    }

    .containers .boxes h3{
        font-size: 26px;
        text-align: center; 
    }

    .containers .boxes p{
        padding-top: 5%;
        font-size: 18px;
        text-align: justify;
    }

    .box.keep-track-mobile > div{
        align-self: center;
    }    

    .box.manage-trips h3{
        margin-bottom: 0;
    }

    .box.manage-trips p{
        padding-top: 10px;
        padding-bottom: 5%;
    }

    .box.advc-mobile{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .box.advc-mobile p{
        margin-top: 20px;
    }

    .box.container-history h3{
        padding-bottom: 30px;
    }

    .box.container-history p{
        order: 2;
        margin: 0 0;
    }

    .box.container-history .h-imgs{
        display: none;
    }
}

@media screen and (max-width: 620px) {
    .box.keep-track-mobile p, 
    .box.advc-mobile p,
    .box.container-history p{
        font-size: 16px;
    }
}

@media screen and (max-width: 500px){

    .containers .title{
        font-size: 32px;
    }

    .box.keep-track-mobile p,
    .box.advc-mobile p,
    .box.container-history p{
        font-size: 13px;
    }
}