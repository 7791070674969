section.home{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

section.home img{
    width: 100%;
    max-width: 300px;
}

.profilePic {
    border-radius: 50%;
    margin-right: 35px;
}

.greeting{
    max-width: 500px;
    width: 100%;
    margin-left: 8%;
}

/* greeting */
.greeting :first-child{
    font-size: 50px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.greeting :nth-child(2){
    font-family: Roboto;
    font-weight: bolder;
    font-size: 25px;
    margin-bottom: 15px;
}

.greeting :nth-child(3){
    width: 80%;
    line-height: 30px;
    text-align: justify;
}

.circle-button-group{
    padding-top: 6%;
    max-width: 500px;
    max-height: 500px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.cb-box{
    width: 50%;
    margin: 0 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.circle-button-group > :first-child{
    align-items: flex-end;
}

.circle-button-group > :last-child{
    align-items: flex-start;
}

.spacer{
    margin-left: 50px;
    margin-right: 50px;
}

.circular-button{
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
}

.circle1 {
    width: 140px;
    height: 140px;
    line-height: 140px;
    border-radius: 50%;
    font-size: 22px;
    text-align: center;
    background: #242F9B
  }

.circle1 a:link { text-decoration: none; color: white; }
.circle1 a:visited { text-decoration: none; color: white;}
.circle1 a:hover{ color: black; text-decoration: none;    }
.circle1 a:active { text-decoration: none; color: black; }

.circle1:hover{
    background-color: aliceblue;
}

/* circle 2 */

.circle2 {
    width: 140px;
    height: 140px;
    line-height: 140px;
    border-radius: 50%;
    font-size: 22px;
    text-align: center;
    background: #646fd4
  }

.circle2 a:link { text-decoration: none; color: white; }
.circle2 a:visited { text-decoration: none; color: white;}
.circle2 a:hover{ color: black; text-decoration: none;    }
.circle2 a:active { text-decoration: none; color: black; }

.circle2:hover{
    background-color: aliceblue;
}

@media screen and (max-device-width: 920px){ 
    .home .greeting{
        margin-left: 0px;
    }

    .cb-box div{
        align-self: flex-start;
    }

    .circle-button-group > :last-child{
        margin-left: -20%;
    }

    .circle1, .circle2{
        width: 120px;
        height: 120px;
        line-height: 124px;
        font-size: 20px;
    }
}

@media screen and (max-device-width: 801px){ 

    .greeting :first-child {
        font-size: 40px;
    }
    
    .greeting :nth-child(2){
        font-size: 20px;
    }
}

@media screen and (max-device-width: 745px){ 
    .home.content > :last-child{
        margin-left: 5%;
    }
}

@media screen and (max-device-width: 710px){ 
    .circle-button-group > :last-child{
        margin-left: -15%;
    }
}

@media screen and (max-device-width: 640px){ 
    .home.content{
        flex-direction: column;
    }
    
    .home.content > :last-child{
        margin: 0 20px;
    }

    section.home .profilePic{
        width: 180px;
        margin: 0;
        padding-top: 20px;
    }
    
    .greeting :first-child{
        margin-bottom: 0;
    }

    .greeting :nth-child(2){
        margin-top: 5px;
    }

    .greeting :nth-child(3){
        width: 100%;
    }

    .cb-box div{
        align-self: center;
    }

    .circle-button-group > :last-child{
        margin-left: 0;
    }
}

@media screen and (max-width: 450px) {
    .greeting :first-child{
        font-size: 30px;
    }

    .greeting :nth-child(2){
        font-size: 18px;
    }
}