footer{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-flow: row;
    flex-wrap: wrap;
    padding-left: 50px;
    padding-right: 50px;
    height: 250px;
}

footer .phone, .copyright{
    flex-grow: 1;
}

footer .email{
    flex-grow: 4;
}

footer div > p:first-child{
    flex-basis: 1;
    font-size: 18px;
}

/* copyright */
footer div > p:nth-child(2){
    font-size: 16px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

footer > div:last-child{
    text-align: right;
}

footer > div:last-child p{
    font-size: 14px;
    text-align: center;
}