header {
    display: flex;
    align-items: flex-end;
    padding-left: 50px;
    padding-right: 50px;
    max-height: 100px;
    padding-bottom: 10px;
}

header div{
    width: 70%;
}

header nav{
    width: 30%;
}

header .name{
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    padding-right: 11%;
    cursor: pointer;
}

.name h2{
    margin-right: 30px;
}

.name p{
    margin-bottom: 22px;
}

.nav-mobile{
    display: none;
}

nav{
    display:flex;
    justify-content: end;
    flex-grow: 1;
    margin-bottom: 6px;
}

nav a:link { text-decoration: none; color: black; }
nav a:visited { text-decoration: none; color: black;}
nav a.active { text-decoration: none; color: #916402ff; }
nav a:hover { text-decoration: none; color: #916402ff;}


.nav-full p{
    text-align: center;
    margin-left: 14px;
    padding-right: 14px;
}

/* nav :nth-child(-n + 2){
    border-right: 1px solid black;
} */

nav.nav-full .link-seperator{
    border-right: 1px solid black;
}

header .name-mob{
    display: none;
    flex-direction: column;  
}

.name-mob div{
    display: flex;
    flex-direction: row;
}

.nav-mobile .dropdown{
    z-index: 500;
    overflow: hidden;
    position: absolute;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    top: 0px;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 5%;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.dropdown p{
    font-size: 26px;
}

.nav-mobile .burger{
    flex-direction: column;
    justify-content: center;
    height: 80px;
    /* display: flex; */
    /* margin-right: 50%; */
}

.burger .menu-image{
    max-width: 57px;
}

.dropdown{
    display: none;
}

label{
    z-index: 1000;
    position: absolute;
    top: 30px;
    right: 35px;
    display:flex;
    align-items: end;
    flex-direction:column;
    width: 50px;
    cursor:pointer;
}

label span{
    background: black;
    border-radius:10px;
    height:4px;
    margin: 3px 0;
    transition: .4s  cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

span:nth-of-type(1){
    width:40%;
}

span:nth-of-type(2){
    width:80%;
}


span:nth-of-type(3){
    width:65%;
}


input[type="checkbox"]{
    display:none;
}


input[type="checkbox"]:checked ~ span:nth-of-type(1){
    transform-origin:bottom;
    transform:rotatez(-45deg) translate(-3px,0px)
}


input[type="checkbox"]:checked ~ span:nth-of-type(2){
    
    transform-origin:top;
    transform:rotatez(45deg)
}


input[type="checkbox"]:checked ~ span:nth-of-type(3){
    
    transform-origin:top;
    width:50%;
    transform: translate(-15px,-4px) rotatez(-45deg);

}

@media screen and (max-width: 755px) {

    header{
        max-height: initial;
    }

    header .name{
        display: none;
    }

    header .name-mob{
        display: flex;
    }

    .name-mob div{
        height: 50px;
    }

    .name-mob p{
        margin-left: 50px;
    }

    /* header h2{
        font-size: 20px;
    }

    header .name p{
        font-size: 13px;
    }

    nav p{
        font-size: 15px;
    } */
    
}

@media screen and (max-width: 620px) {
    header{
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 528px) {
    
    .nav-full{
        display: none;
    }
    
    .nav-mobile{
        display: flex;
        opacity: 1;
    }

    nav p{
        font-size: 20px;
    }
    
    .nav-mobile .dropdown{
        display: flex;

    }
    
    header div{
        width: initial;
    }

    .name-mob{
        min-width: 80%;
    }

    .nav-mobile{
        width: 20%;
    }
}

@media screen and (max-width: 340px) {
    header h2{
        font-size: 18px;
    }

    div > p:nth-child(2){
        font-size: 14px;
        margin-top: 2px;
        margin-left: 45px;
    }
}