.resume{
    background-color: white;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
}

.resume .row:first-child .left{
    justify-content: start;
    padding-left: 75px;
}

.resume .left .title{
    margin-bottom: 50px;
    font-size: 38px;
}

.resume .row{
    display: flex;
    justify-content: center;
}

.resume .row .left{
    display: flex;
    justify-content: center;
    width:50%;
    margin-left: 100px;
}

.resume .left h1{
    text-align: center;
    font-size: 26px;
}

.resume .left{
    width: 30%;
}

.resume .right{
    width: 70%;
    margin-right: 30px;
}

.resume .segment{
    padding-top: 10px;
    padding-bottom: 10px;
}

.resume li{
    max-width: 600px;
}

.resume .work-title{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: -12px;
}

.resume .location{
    font-style: italic;
}

.resume ul{
    margin-top: 30px;
}

.resume .bullet-title{
    margin-left: -20px;
}

.resume .bullet-title:nth-of-type(2){
    padding-top: 25px;
}

.resume ul.sub-bullets{
    margin-top: 0px;
    padding-bottom: 10px;
}

.box.sheepbuy.portfolio{
    min-height: auto;
    margin-bottom: 100px;
}

@media screen and (max-width: 1205px){

    .resume .row.title .left{
        padding-left: 0;
        width: 100%;
        justify-content: start;
    }
}

@media screen and (max-width: 980px){
    .resume .row .left{
        justify-content: start;
    }

    .row .left h1{
        font-size: 24px;
    }
}

@media screen and (max-width: 880px){
    .resume .row .left{
        margin-left: 70px;
    }

    .resume .row .right{
        margin-left: 30px;
    }
}

@media screen and (max-width: 750px){

    .row .left, .row .right{
        width: 100%;
    }

    .resume > :nth-child(n+2){
        flex-direction: column;
        align-items: center;
    }

    .resume .row .left, 
    .resume .row .right{
        display: flex;
        flex-direction: column;
    }

    .resume .segment{
        padding: 0px 10%;
        width: 100%;
    }

    .resume .row .left{
        margin-left: 0;
    }

    .resume .row.title .left{
        justify-content: center;
        margin-bottom: -20px;
    }

    .resume .row.title .right{
        display: none;
    }
}

@media screen and (max-width: 400px){

    .resume .row.title .left{
        justify-content: center;
        margin-bottom: -40px;
    }

    .row .left h1{
        font-size: 20px;
    }

    .right p{
        font-size: 13px;
    }

    p.bullet-title{
        font-size: 16px;
    }
}