.portfolio{
    background-color: white;
}

.portfolio .boxes > :first-child{
    justify-content: start;
    padding-left: 175px;
}

.portfolio .boxes > :nth-child(1),
.portfolio .boxes > :nth-child(2){
    min-height: auto;
    width: 50%;
}

.portfolio .title{
    width: 485px;
    font-size: 38px;    
    text-align: left;
}

.portfolio .box{
    min-height: 622px;
    height: 100%;
}

.portfolio .custom-line{
    width: 85%;
    margin-top: 80px;
    margin-bottom: 80px;
    border-color: #b0eaea;
    align-self: center;
}

/* .portfolio .title{
    text-align: left;
    margin-left: 300px;
    margin-bottom: 50px;
    font-size: 38px;    
} */

.project{
    justify-content: flex-end;
    align-items: center;
}

.project div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 485px;
}

.portfolio .project-img{
    width: 100%;
    height: 100%;
    max-width: 265px;
    max-height: 495px;
}

.portfolio .project-img-container{
    width: 100%;
    height: 100%;
    max-width: 229px;
    max-height: 494px;
}

.portfolio .project-thumbnail{
    margin-top: 10px;
    margin-bottom: 35px;
    max-width: 100px;
    border-radius: 50%;
}

.portfolio a{
    align-self: flex-start;
}

.project-link p{
    margin: 5px;
}

.description{
    text-align: justify;
    line-height: 30px;
}

.box.sheepbuy{
    min-height: 398px;
    width: 100%;
}

.sheepbuy div{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 490px;
    align-items: center;
}

.box .sheepbuy-logo{
    width: 236px;
}

.sheepbuy{
    align-items: center;
    flex-direction: column;
}

.sheepbuy a{
    align-self: center;
}

@media screen and (max-width: 1317px){

    .portfolio .boxes{
        padding: 0 30px;
    }

    .portfolio .boxes > :first-child{
        padding-left: 0;    
        justify-content: end;
    }
}

@media screen and (max-width: 845px){
    .portfolio .project-img{
        max-width: 230px;
        /* max-height: 430px; */
    }

    .portfolio .project-img-container{
        max-width: 200px;
        /* max-height: 483px; */
    }

    .portfolio .custom-line{
        margin-top: 60px;
        margin-bottom: 10px;
    }

    .portfolio .cl-2{
        margin-top: 50px;
        margin-bottom: 60px;
    }

    .box.sheepbuy{
        justify-content: start;
        min-height: 350px;
    }
}

@media screen and (max-width: 800px){

    .portfolio .project-img{
        max-width: 200px;
    }

    .portfolio .project-img-container{
        max-width: 180px;
    }

}

@media screen and (max-width: 730px){

    .portfolio .boxes{
        flex-direction: column;
        padding: 0 8%;
    }

    .portfolio .boxes > :first-child{
        width:100%;
        justify-content: center;
    }

    .portfolio .boxes > :nth-child(2){
        display: none;
    }

    .portfolio .boxes .box{
        width: 100%;
        justify-content: center;
    }

    .portfolio .box{
        min-height: auto;
    }

    .portfolio .title{
        text-align: center;
        /* margin-bottom: 20px; */
    }

    .project div{
        width: 100%;
    }

    .portfolio a{
        align-self: center;
        margin-bottom: 20px;
    }

    .portfolio .custom-line{
        width: 100%;
        margin-top: 50px;
        margin-bottom: 40px;
    }

    .portfolio .cl-2{
        margin-bottom: 60px;
    }

    .sheepbuy div{
        max-width: none;
    }
}

@media screen and (max-width: 400px){
    .portfolio .box .description{
        font-size: 13px;
    }
}