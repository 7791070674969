.wsg{
    background-color: white;
    padding-top: 50px;
}

.wsg .box{
    min-height: 550px;
}

.wsg img{
    width: initial;
    max-width: 230px;
    max-height: 490px;
}

.title-section{
    display:flex;
    flex-direction: column;
    align-items: center;
}

.wsg p{
    margin: 0px;
    line-height: 27px;
}

.wsg .title-section p{
    font-size: 40px;
    margin-bottom: 15px;
}

.app-store{
    margin-bottom: 45px;
    width: 120px;
    height: 40px;
}

.box.get-active-mobile{
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.box.ga-mobile, 
.box.choose-location-mobile,
.box.wsg-history-mobile{
    display: none;
}

.get-active{
    flex-direction: column;
    align-self: stretch;
    justify-content: center;
    background-color: #F1F1F1;
    padding-right: 8%;
}

.get-active h3, .get-active p {
    padding-left: 15%;
}

.choose-location{
    flex-direction: column;
    align-items: center;
    background-color: #F1F1F1;
}

.choose-location-carousel{
    display: none;
    justify-content: center;
    align-items: center;
}

.cl-container{
    max-width: 230px;
}

.choose-location-images{
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-left: 7%;
}

.choose-location-images img{
    margin-right: 4%;
    width: 50%;
}

.choose-location p{
    text-align: center;
    padding: 0 5%;
}

.mark-completed, .view-edit, .wsg-history{
    padding: 4% 0;
}

.mark-completed h3, .view-edit h3{
    margin-top: 0;
}

.mark-completed{
    justify-content: end;
    align-items: center;
    padding-right: 5%;  
}

.mark-completed div, .view-edit div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* .mark-completed div :nth-child(3), 
.view-edit div :nth-child(3) {
    min-height: 66px;
} */

.mark-completed div img, .view-edit div img{
    margin-bottom: 20px;
}

.mark-completed div p, .view-edit div p{
    margin: 0 15px;
}

.view-edit{
    justify-content: start;  
    padding-left: 10%;
}

.get-active p, .choose-location p{
    max-width: 700px;
    font-size: 15px;
    line-height: 27px;
}

.wsg .wsg-history{
    flex-direction: column;
    align-items: center;
    background-color: #F1F1F1;
    width:100%;
}

.wsg-history h3{
    margin-bottom: 6px;
}

.wsg-history p{
    margin-bottom: 18px;
}

@media screen and (max-width: 1130px) {

    .choose-location-images{
        padding-left: 2%;
    }
}

@media screen and (max-width: 1015px) {

    .choose-location-images{
        display: none;
    }

    .choose-location-carousel{
        display: flex;
    }

    .get-active{
        padding-right: 0;
    }

    .get-active h3{
        padding-left: 0;
        text-align: center;
    }

    .get-active p, .choose-location p{
        text-align: justify;
        padding: 0 8%;
    }
}

@media screen and (max-width: 730px){
    /* hide desktop boxes */
    .wsg .box {
        display: none;
    }

    /* show mobile boxes */
    .box.ga-mobile, 
    .box.choose-location-mobile,
    .box.mark-completed,
    .box.view-edit,
    .box.wsg-history-mobile{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
    }

    .wsg .box h3{
        margin-top: 0;
    }

    .wsg .box{
        padding-top: 10%;
        padding-left: 8%;
        padding-right: 8%;
        padding-bottom: 10%;
    }

    .wsg .boxes h3{
        font-size: 30px;
        text-align: center; 
    }

    .wsg .boxes p{
        padding-top: 5%;
        font-size: 18px;
        text-align: justify;
    }
    
    .box.ga-mobile,
    .box.mark-completed,
    .box.wsg-history-mobile {
        background-color: #F1F1F1;
    }

    .box.choose-location-mobile > div{
        width: 230px;
        align-self: center;
    }

    .wsg .mark-completed p, 
    .wsg .view-edit p {
        padding-top: 0;
    }
}

@media screen and (max-width: 580px) {

    .mark-completed{
        padding-right: 2%;
    }

    .view-edit{
        padding-left: 2%;
    }

    .wsg .boxes p{
        font-size: 16px;
    }
}

@media screen and (max-width: 500px){
    .wsg .boxes p{
        font-size: 13px;
    }
}